@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.container {
    background-color: antiquewhite;
    width: calc(100% + 30px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #dfe2e5;
    margin-top: -50px;
    margin-inline-start: -30px;
    min-height: 100vh;
    padding: 50px 120px;
}


.pathHeader {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 16px;
    color: #303233;
    line-height: 100%;
}

.pathHeader span {
    font-weight: bold;
}


.formContainer {
    margin-top: 6%;
    background-color: white;
    border-radius: 8px;
    width: 90%;
    overflow: hidden;
    max-width: 610px;
    min-width: 350px;
    max-height: fit-content;
    padding: 32px;
    font-family: "Inter";

}

.formContainer header {
    display: grid;
    grid-template-columns: 95px 2px 1fr;
    grid-template-rows: repeat(2, 1fr);
    height: 58px;
    column-gap: 23px;
}

.headerIcon {
    background-position: center;
    background-repeat: no-repeat;
    grid-row: 1/3;
}

.year-1 {
    background-image: url("../../../images/grade/grade-1.svg");
}

.year-2 {
    background-image: url("../../../images/grade/grade-2.svg");
}

.year-3 {
    background-image: url("../../../images/grade/grade-3.svg");
}

.headerDivider {
    background-color: #A7B9CC;
    grid-row: 1/3;
}

.headerItem {
    display: flex;
    align-items: center;
    gap: 12px;

}

.chapterItem {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #757778;

}

.headerItem svg {
    scale: 0.9;
}

.sessionItem {
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    align-items: flex-end;
}

.formContainer hr {
    margin-top: 30px;
    margin-bottom: 40px;
    color: #A7B9CC;
    border-radius: 2px;
    height: 1px;
    opacity: 1;
}

.formContainer h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    color: black;
    margin-bottom: 40px;
}

.formContainer h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #666666;
}

.buttons {
    margin-top: 32px;
    width: 100%;
}

.payByWallet h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    margin-bottom: 16px;
}

.payByWallet p {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    margin: 0;
    color: #666666;
}

.payByWallet span {
    font-weight: bold;
    color: black;
}

.payByScratchCard input {
    padding: 0 16px;
    margin-top: 16px;
    appearance: none;
    background: #F6F7F9;
    border-radius: 10px;
    height: 48px;
}

.payByScratchCard input::placeholder {
    color: #A7B9CC;
    font-weight: 400;
    font-size: 14px;
}

.confirmCard div {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.confirmCard span {
    font-weight: bold;
}

.payByScratchCard input::-webkit-outer-spin-button,
.payByScratchCard input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.payByScratchCard input[type=number] {
    -moz-appearance: textfield;
}
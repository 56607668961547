@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.container {
    font-family: "Inter";
    display: grid;
    grid-template-columns: 1fr auto;
    margin-inline: 20px;
    gap: 10px;
}

.questionContainer {
    margin-bottom: 50px;
}

.questionContainer header p, .sheetHeader {
    font-size: 19px;
}

.sectionTitle {
    font-weight: bold;
    font-size: 26px;
    padding: 16px 0px;
    border-bottom: 1.5px solid lightgrey;
    margin-bottom: 32px;
    position: sticky;
    top: 0;
    background-color: white !important;
}

.questionHeader {
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 6px;
}

.questionCard {

}

.questionCard header, .questionCard p {
    margin-bottom: 8px;
}

.sheetText {
    font-size: 16px;
    border-radius: 8px;
    border: 1.5px solid lightgrey !important;
    margin-bottom: -12px;
}

.sheetText:focus {
    border-color: #001731 !important;
}

.imageButton {
    border-radius: 8px;
    border-color: #001731;
    color: #001731;
}

.counter {

    position: sticky;
    width: 170px;
    height: 170px;
    padding: 20px;
    border: 1px solid lightgrey;
    z-index: 4;
    top: 20px;
    background: white;
    border-radius: 8px;
}
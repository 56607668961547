.questionImageUpload {
    border: none !important;
    outline: none;
    margin-top: 8px;
}

.questionImageUpload::-webkit-file-upload-button {
    background-color: #001731;
    color: transparent;
    width: 48px;
    height: 48px;
    /* display: block; */
    cursor: pointer;
    background-image: url("../images/add.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 36px;
    margin-bottom: 2px;
}


.choiceTitle {
    margin: 36px 24px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.choiceLabel {
    padding: 0 12px !important;
    margin-top: 18px;
}

.choiceItem {
    padding-top: 0px !important;
}

.imageContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    place-items: center;
    height: 100%;
    min-height: 70px;
}

.imageContainer img {
    grid-row: 1/1;
    grid-column: 1/1;
}

.imageContainer .changeContainer {
    grid-row: 1/1;
    grid-column: 1/1;
    width: 100%;
    height: 100%;
    background: lightgray;
    opacity: 0;
    transition: opacity 200ms ease-in;
}

.addContainer {
    opacity: 1 !important;
}

.changeContainer:hover {
    opacity: 0.6;
}

.changeContainer input {
    display: none;
}

.changeContainer label {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.container {
    padding-inline: 50px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 32px;
}

.container input {
    outline: none;
    border: none !important;
}

.card label {
    font-weight: bold;

}

.card > label {
    font-size: 20px;
    margin-bottom: 10px;
    margin-inline-start: 5px;
}

.card {
    padding: 20px;
    border-radius: 12px;
    border: 1px solid lightgrey;
    gap: 8px;
}

.inputsContainer {
    display: flex;
    gap: 8px;
}

.inputContainer {
    flex: 1 1 0;
    padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid lightgrey;
    outline: none;
    font-size: 16px;
}

.buttonsContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    gap: 8px;
}

@import "video-react/dist/video-react.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "~antd/dist/antd.css";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&family=Nunito:wght@400;500;600&family=Poppins:wght@500;600&family=Raleway:wght@400;500&display=swap");

/* .slick-slide {
 margin: 0 27px;
}
.slick-list {
 margin: 0 -27px;
} */

body {
    height: fit-content !important;
}

.ant-layout-sider {
    padding: 0px 10px;
}

.ant-menu {
    background-color: unset;
}

/* .App,
.MuiTypography-root {
  font-family: "Avenir Next Font" !important;
} */

.videosCard {
    width: 510px;
    height: 287px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
}

.videosCardCourseTitle {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24.9846px;
    color: #ffffff;
}

.videosCardSessionTitle {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24.9846px;
    color: #ffffff;
}

.ant-select {
    width: 90% !important;
}

/* .heading{
  color: #2E384D;
  font-size: 25px;
  margin-bottom: 35px;
} */
.sub-header {
    font-size: 20px;
    color: #2e384d;
    display: inline-block;
    cursor: pointer;
    background-color: #fff !important;
    border: unset !important;
    box-shadow: unset !important;
}

.ant-layout {
    background-color: transparent !important;
}

#dash {
    height: 2px;
    background-color: #001731;
    width: 13%;
}

.logo {
    margin: 16px 0px 0px 15px;
    width: 200px;
}

.pagePadding {
    padding-top: 50px;
}

.fas {
    cursor: pointer;
}

.home-courses {
    text-align: -webkit-center;
}

.home-courses .card-body {
    padding: 20px 0px;
}

.home-courses .card {
    position: relative;
    border: unset !important;
}

.overlay {
    background-color: #001731;
    position: absolute;
    top: 37%;
    color: white;
    font-weight: bold;
    right: -4%;
    width: 85px;
    height: 37px;
    box-shadow: 0px 17px 32px #0000002b;
    border-radius: 5px;
    font-size: 21px;
}

.overlay2 {
    width: 85px;
    height: 27px;
    font-size: 15px;
}

.overlay2 a,
.overlay2,
.reviewBtn {
    color: #001731 !important;
    cursor: pointer;
}

.overlay3 {
    color: #001731;
    width: 85px;
    height: 27px;
    font-size: 15px;
    cursor: pointer;
}

.homeCoursesBtn {
    margin-left: 25px;
}

/* .card-img-top {
  height: 200px!important;
} */
.courseBtn1,
.courseBtn1:hover {
    background: #001731 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 24px #0000000f;
    border-radius: unset;
    border: unset !important;
    /* margin-top: 15px; */
    color: white !important;
}

.title1 {
    font-weight: 600;
    font-size: 30px;
    line-height: 100%;
    align-items: center;

    color: #5a6c7f;
}

.title2 {
    font-size: 20px;
}

.rdt_TableCol,
.rdt_TableCell {
    -webkit-justify-content: unset !important;
}

.rdt_TableCol {
    padding-left: 0px !important;
}

.Toastify__toast--info {
    background: #2d75a5;
}

.rdt_TableHead {
    font-weight: bold !important;
    font-size: 15px !important;
}

.Toastify__toast {
    position: relative;
    min-height: 100px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    display: flex;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
}

.rdt_Table {
    border: solid 1px #bcbebd;
}

.rdt_TableCell,
.rdt_TableCol {
    border-left: solid 1px #bcbebd;
}

.rdt_TableHeadRow {
    border-bottom: solid 1px #bcbebd;
}

.form-label {
    color: #8c98a9;
    font-size: 15px;
    margin-top: 1rem;
    margin-bottom: 0.2rem;
}

.actionIcon {
    color: #001731;
    cursor: pointer;
}

.ant-ribbon {
    background-color: #001731 !important;
}

.studentInfo {
    background-color: #001731 !important;
    color: white !important;
    font-size: 15px !important;
}

.Toastify__toast {
    background-color: #001731 !important;
    color: white !important;
}

.Toastify__close-button--light {
    color: white !important;
}

.searchStudent {
    width: 45%;
    border: solid 1px grey;
}

.error {
    color: red;
}

textarea,
input {
    display: block;
    width: 100%;
    border: solid 1px #d9d9d9 !important;
}

textarea:focus-visible,
input:focus-visible {
    outline: unset !important;
}

.viewTxt {
    color: #001731;
    cursor: pointer;
    font-weight: bold;
}

.ant-modal-footer {
    display: none !important;
}

.MuiPaper-root {
    height: unset !important;
}

@media (max-width: 700px) {
    .pagePadding {
        padding: unset;
    }

    .MuiPaper-root {
        box-shadow: unset !important;
    }
}

.centering {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rdt_TableCol_Sortable {
    padding-inline-start: 10px !important;
}

/* for desktop */
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background: linear-gradient(99.62deg, #0aad8a -98.01%, #0aad69 76.21%);
    color: #fff !important;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
/*@media screen and (max-width: 767px) {*/
/*  .whatsapp-icon {*/
/*    margin-top: 10px;*/
/*  }*/

/*  .whatsapp_float {*/
/*    width: 40px;*/
/*    height: 40px;*/
/*    bottom: 20px;*/
/*    right: 10px;*/
/*    font-size: 22px;*/
/*  }*/
/*}*/

.filterButtons {
    display: flex;
    align-items: flex-end;
    justify-content: end;
}

.exported-row {
    display: flex;
    align-items: center;
}

.exported-row button {
    height: 20px;
    width: 20px;
    background: green;
    border: none;
    border-radius: 50%;
}

/* .exported-row button::after{
  content: "Send";
} */

.exported-row .whatsapp_float {
    background-color: #25d366;
    color: #fff !important;
    border-radius: 50px;
    text-align: center;
    font-size: 23px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    position: unset;
    margin-inline-end: 4px;
}

.exported-row .whatsapp-icon {
    margin-top: 0px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .exported-row .whatsapp-icon {
        margin-top: 10px;
    }

    .exported-row .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}

.ant-picker-input > input,
.ant-input > input,
.ant-input-password > input {
    border: unset !important;
}

.MuiSvgIcon-root {
    height: 4rem !important;
}

.dropdown-menu[data-bs-popper] {
    left: unset !important;
    right: 0% !important;
}

.fawryBtn {
    width: 188px;
    height: 42px;
    display: inline !important;
}

.addBtn {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 115px;
    right: 40px;
    background: #fff;
    color: #001731 !important;
    border-radius: 50px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 100;
    align-items: center;
    justify-content: center;
    display: flex;
}

.addBtn .fa-plus {
    background-color: transparent !important;
    border: unset !important;
    font-size: 30px;
}

.courseDetails {
    color: rgb(180, 176, 176);
    font-size: 14px;
}

.actionBtns a {
    color: #001731 !important;
    margin: 0px 10px;
}

.info-div {
    border-left: solid 12px #6fc4ad;
    border-radius: 3.3288px;
}

.info-div .info1 {
    font-family: "Raleway", sans-serif;
    text-transform: capitalize;
    /* font-family: "Raleway"; */
    font-style: normal;
    font-weight: 400;
    font-size: 39.8455px;
    line-height: 100%;
    color: #98a3af;
    margin-bottom: 0em !important;
}

.info-div .info2 {
    /* font-family: "Raleway"; */
    font-family: "Raleway", sans-serif;
    text-transform: capitalize;

    font-style: normal;
    font-weight: 500;
    font-size: 39.8455px;
    line-height: 100%;
    color: #5a6c7f;
}

.watching-title {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 65px;
    align-items: center;
    margin-bottom: 0em !important;

    color: #011d3a;
}

.sub1 {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    align-items: center;
    color: #5a6c7f;
}

.notify-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 12.72px;

    width: 493px;
    height: 159.8px;

    background: #e7f2ff;
    border-radius: 10.1728px;
}

.notify-div .title {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    mix-blend-mode: normal;

    color: #011d3a;
}

.notify-div .time {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    mix-blend-mode: normal;
    color: #737980;
}

.notify-div .content {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    /* text-align: right; */
    mix-blend-mode: normal;
    color: #737980;
}

.timep {
    font-size: 20px;
    font-weight: bold;
}

.status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.eduLevel {
    border-radius: 8px;
    border-color: #fff !important;
    font-weight: 600;
}

.centerBtn,
.centerBtn:hover,
.centerBtn:active {
    background: #ffffff !important;
    border-radius: 8px;
    font-weight: 600;
    width: 80px;
    box-shadow: unset !important;
}

.ant-table-thead
> tr
> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: unset !important;
}

.ant-table-thead {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #011d3a;
    background: #f7faff !important;
}

.nameStyle {
    color: #011d3a;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    margin-bottom: 0rem;
}

.numStyle {
    font-weight: 400;
    line-height: 150%;
    color: #5a6c7f;
}

.tableBtn,
.tableBtn:hover,
.tableBtn:active {
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    border: #fff !important;
}

.filterHead {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    color: #011d3a;
}

.searchStudent {
    background: #ffffff;
    border: 1px solid #e0e7ed;
    border-radius: 8px;
    width: 70%;
}

.tableFilters.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #3ea2ff !important;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    color: #fff !important;
    border: unset !important;
}

.tableFilters .ant-select-arrow .anticon {
    color: #fff !important;
}

.filterTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    color: #5a6c7f;
    margin-bottom: 0rem !important;
}

.studentPaination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.studentPaination .ant-pagination-item {
    background: #f1f7ff;
    border-radius: 8px;
}

.studentPaination .ant-pagination-item-active {
    border: unset !important;
    background: #6fc4ad !important;
    border-radius: 8px;
    color: #fff !important;
}

/*****************************profile******************************************/
.codeBtn {
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    /* identical to box height, or 18px */
    box-shadow: unset !important;
    color: #011d3a !important;
    background-color: transparent !important;
    border: 2.5px solid #011d3a;
    border-radius: 34px;
}

.studentName {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
    color: #011d3a;
    margin-bottom: 0rem;
}

.firstBtn,
.firstBtn:active,
.firstBtn:hover {
    background-color: #b9f5fd !important;
    color: #1abbb9 !important;
    font-weight: "600";
    box-shadow: unset !important;
}

.secBtn,
.secBtn:active,
.secBtn:hover {
    background-color: #f9dcba !important;
    color: #da9d55 !important;
    font-weight: "600";
    box-shadow: unset !important;
}

.thirdtBtn,
.thirdtBtn:active,
.thirdtBtn:hov {
    background-color: #ff6a6a !important;
    color: #b72222 !important;
    font-weight: 600;
    box-shadow: unset !important;
}

.pStyle {
    margin-bottom: 0rem;
}

.personalInfoTitle {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #6fc4ad;
}

.profileLabel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */

    /* black 70 */

    color: #5f6377;
}

.ant-input[disabled] {
    background: #dadada !important;
    border-radius: 5px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #787878;
}

.resetBtn,
.resetBtn:active,
.resetBtn:hover {
    background-color: transparent !important;
    color: #ff6a6a !important;
    font-weight: 600;
    border: 2.5px solid #ff6a6a !important;
    border-radius: 30px;
    box-shadow: unset !important;
}

.resetChanges,
.resetChanges:hover,
.resetChanges:active {
    background-color: transparent !important;
    color: #ff6a6a !important;
    font-weight: 600;
    border: 2.5px solid #ff6a6a !important;
    border-radius: 11px;
    box-shadow: unset !important;
    height: 40px;
    width: 140px;
}

.applyChanges,
.applyChanges:hover,
.applyChanges:active {
    background-color: #12ca88 !important;
    color: #fff !important;
    font-weight: 600;
    border: unset !important;
    border-radius: 11px;
    box-shadow: unset !important;
    height: 40px;
    width: 140px;
}

.detailsRow {
    background: #efefef;
    border-radius: 11px;
}

.detailsPage {
    position: relative;
}

.commentsDiv {
    position: absolute;
    background: #6fc4ad;
    border-radius: 7px 0px 0px 7px;
    right: 0px;
    top: 10%;
    padding: 10px;
}

.commentsDiv p,
.commentsDiv a,
.commentsDiv a:hover {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    cursor: pointer;
    color: #ffffff !important;
}

.changePassTitle {
    color: #2e384d;
    font-size: 15px;
    font-weight: 600;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                       supported by Chrome, Edge, Opera and Firefox */
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
}

.noselect.dragging * {
    pointer-events: none;
}

.grayBody {
    background-color: #dfe2e5 !important;
}

@media print {
    :root {
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .ant-layout ant-layout-has-sider {
        display: none;
    }

    aside {
        display: none;
    }

    .printBtn {
        display: none;
    }
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.report .infoDiv p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    /* or 22px */

    display: flex;
    align-items: center;

    color: #555555;
}

.infoDiv2 {
    border: solid 1px #cbd2d6;
    /* width: 30%; */
}

.infoDiv2 .p1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: left !important;
    color: #000000;
}

.infoDiv2 .p2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: #000000;
}

.reportInfoRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.reportBtn button {
    border-radius: 8px;
    font-weight: 500;
    font-size: 18px;
    width: 191px;
    height: 41px;
}

.reportBtn .btn1 {
    background: #008f97;
    border: 2px solid #008f97;
    font-size: 18px;
    color: #ffffff;
}

.reportBtn .btn2,
.btn4 {
    color: #008f97;
    font-size: 18px;
}

.reportBtn .btn3 {
    color: #008f97;
    border: 2px solid #008f97;
    font-size: 18px;
    background: rgba(0, 143, 151, 0.13);
}

.reportTable .ant-table-thead {
    /* border: solid 2px #006481 !important; */
}

.reportTable .ant-table-thead .ant-table-cell {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}
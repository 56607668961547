.container {
}

.progress {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
}

.progress > * {
    grid-row: 1/2;
    grid-column: 1/2;
}

.counterText {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #0AAD8A;
    font-weight: bold;
}

.counterText header {
    font-size: 27px;
    line-height: 27px;
}

.counterText div {
    font-size: 11px;
}

.warning {
    color: #FF8600;
}
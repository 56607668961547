:root {
    --font-question: 18px;
    --font-choice: 17px;
    --color-background: transparent;
    --color-border: lightgrey;
}

.selected {
    --color-background: #001731;
    --color-border: #001731;
    --background-image: url("./resources/check.svg");
}

.questionCard img {
    max-width: 100%;
    border-radius: 4px;
}

.questionHeader {
    font-size: var(--font-question);
    text-align: justify;
    margin-bottom: 42px;
}

.choicesList {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.choice {
    font-size: var(--font-choice);
    text-align: justify;
    border: 1.5px solid var(--color-border);
    padding: 16px 24px;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr 30px;
    gap: 24px;
    align-items: center;
}

.choice p {
    margin-bottom: 0;
    display: inline-block;
}

.choice img {
    border-radius: 4px;
    margin-bottom: 8px;
    grid-column: 1/3;
}

.answerState {
    transition: background-color 100ms ease-in;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    border: 2px solid var(--color-border);
    background-color: var(--color-background);
    background-image: var(--background-image);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
}

.wrapper {
  width: 100%;
  /* height: 100%; */
  align-items: center;
  justify-content: center;
}

.file-upload {
  height: 60px;
  width: 60px;
  /* border-radius: 100px; */
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 4px solid #ffffff;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #001731 50%, #ffffff 50%);
  background-size: 100% 200%;
  transition: all 1s;
  color: #ffffff;
  font-size: 30px;
}
.file-upload input[type="file"] {
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-upload :hover {
  background-position: 0 -100%;

  color: #001731;
}

.file-upload2 {
  height: 60px;
  /* width: 60px; */
  /* border-radius: 100px; */
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 4px dashed #001731;
  overflow: hidden;
  background-color: linear-gradient(to bottom, #fff 50%, #ffffff 50%);
  background-size: 100% 200%;
  transition: all 1s;
  color: #001731;
  font-size: 13px;
}
.file-upload2 input[type="file"] {
  height: 200px;
  /* width: 200px; */
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-upload2 :hover {
  background-position: 0 -100%;

  color: #001731;
}
.editSessionIcon {
  font-size: 20px;
  cursor: pointer;
}

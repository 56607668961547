.container {
    margin-top: 6px;
}

.container h6 {
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.container h6 span {
    font-weight: bold;
}

.questionsGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 16px;
    flex: 1;
}

:root {
    --color-border: lightgray;
    --color-background: transparent;
}

.selected {
    --color-border: #001731;
    --color-background: rgba(0, 23, 49, 0.25);
}

.questionGridItem {
    outline: none;
    border: 1px solid var(--color-border);
    background-color: var(--color-background);
    font-size: 12px;
    border-radius: 3px;
    width: 34px;
}
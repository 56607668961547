@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.container {
    --color-primary: #0aad69;
    --color-background: #fff;
    padding-top: 30px;
    margin-inline: 30px;
    font-family: "Inter";
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 125px;
}

.questionsProgress {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-inline-start: 39px;
    z-index: 3;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;

}

.questionsProgress::-webkit-progress-bar,
.questionsProgress::-moz-progress-bar {
    background-color: lightgrey;
    height: 20px;
}

.questionsProgress::-webkit-progress-value {
    background-color: var(--color-primary);
    transition: width 200ms ease-in-out;
}

.questionNumber {
    margin-bottom: 32px;
    /*grid-column: 1/3;*/
}

.questionNumber strong {
    font-size: 35px;
}

.questionNumber span {
    font-size: 18px;
}

.content {
    margin-bottom: 24px;
}

.navigation {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    padding: 8px;
    margin-inline: -8px;
    margin-bottom: 8px;
    border-radius: 8px;
    position: sticky;
    bottom: 8px;
    background: var(--color-background);
}

.navigation button {
    padding-inline: 48px;
    flex-grow: 0;
    box-shadow: none;
}

.submitButton {
    background: #001731;
}

.submitButton:hover {
    background: #001731;
}


.questionsContainerBackground {
    background-color: white;
    height: 100%;
    z-index: 2;
}

.questionsContainer {
    position: sticky;
    top: 30px;
    height: fit-content;
    background-color: white;
    z-index: 2;
}

@media screen and (min-width: 701px) {
    .container {
        margin-top: -50px;
    }
}


.slideForward {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    z-index: 2;
    right: -20%;
    -webkit-animation: slideForward 200ms forwards;
    -webkit-animation-delay: 2s;
    animation: slideForward 200ms forwards;
    /*animation-delay: 500ms;*/
}

.counter {
    margin-bottom: 24px;
}

@-webkit-keyframes slideForward {
    100% {
        right: 0;
    }
}

@keyframes slideForward {
    100% {
        right: 0;
    }
}

.slideBackward {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    left: -20%;
    -webkit-animation: slideBackward 200ms forwards;
    -webkit-animation-delay: 2s;
    animation: slideBackward 200ms forwards;
}

@-webkit-keyframes slideBackward {
    100% {
        left: 0;
    }
}

@keyframes slideBackward {
    100% {
        left: 0;
    }
}
.container {
    text-align: center;
}

.container textarea {
    margin-top: 24px;
    margin-bottom: 16px;
}

.container button {
    padding: 6px 32px;
}


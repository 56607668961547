@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');


.container {
    margin-inline: 40px;
}

.container header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
    position: relative;
    width: fit-content;
}

.container header select {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% + 30px);
    border: none;
    outline: none;
    color: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");

    background-position: right;
    background-repeat: no-repeat;
}

.selectedEduLevel {
    color: black;
}

.container header h1 {
    margin: 0;
    font-size: 20px;
    color: black;
    font-weight: bold;
    font-family: 'Noto Serif', serif;
}

.courses {
    display: grid;
    grid-template-columns: repeat(3, minmax(310px, 1fr) );
    gap: 16px;
}



.card {
    box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
    padding: 10px 20px;
    border-radius: 10px;
}

.chapterActions {
    display: grid;
    gap: 4px 8px;
    grid-template-columns: repeat(2, auto);
    align-content: space-between;
}


.chapterActions a {
    color: #001731 !important;
    font-weight: 500;
}

.chapterActions a i {
    margin-inline-end: 6px;
    color: #6fc4ad
}

.cardHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;

}

.cardHeader h4 {
    flex: 1;
}

@media screen and (max-width: 1100px) {
    .courses {
        grid-template-columns: repeat(2, minmax(310px, 1fr) );
    }
}

@media screen and (max-width: 790px) {
    .courses {
        grid-template-columns: minmax(310px, 1fr) !important;
    }
}
.optionsContainer {
    display: flex;
    flex-direction: row;
    gap: 40px;
    flex-wrap: wrap;
    margin-top: 24px;
    margin-bottom: 40px;
}


.optionButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    font-weight: 500;
    font-size: 16px;
    color: #202020;
    background-color: transparent;
    border: 1px solid #a9b3c1;
    border-radius: 10px;
    transition: background-color 200ms ease-in-out,
    border-color 200ms ease-in-out,
    box-shadow 150ms ease-in-out;

}

.optionButton:hover,
.optionButtonSelected {
    background: rgba(6, 173, 138, 0.05);
    border: 1px solid #06AD8A;
}

.optionButtonSelected {
    box-shadow: 0px 9px 14px -9px rgba(0, 0, 0, 0.25);
}


.container {
    margin-inline: 30px;
}

.container header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
}

.tab {
    margin-inline: 4px;
    border: 2px solid #001731;
    border-radius: 8px;
    padding: 4px 24px;
    background-color: transparent;
    font-size: 18px;
    color: #001731;
    transition: background-color 200ms ease-in;
}

.selected {
    background-color: #001731;
    color: white;
}

.content {
    margin: 32px 0;
}

.content img {
    width: 100%;
}
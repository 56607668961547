.grayBody {
    background-color: #dfe2e5 !important;
}

.container {
    width: 50%;
    max-width: calc(100% - 250px);
    min-width: 350px;
    overflow-x: hidden;
    margin-inline-start: 40px;
}


.column {
    width: 100%;
}

.notification {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
}

/*1 1.78*/
.videosCard {
    max-width: 510px;
    width: 100%;
    aspect-ratio: 18/10;
    margin-bottom: 20px;
    border-radius: 24px;
    background-color: #001731;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
}

.videosCard .info {
    width: 100%;
    height: 100%;
    padding: 18px 18px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 25%, rgba(0, 0, 0, 0.5695320364473915) 99%);

}

.info .play {
    width: 100%;
    height: 100%;
    background-image: url("../../../images/play.svg");
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: 47% 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}


@media screen and (max-width: 700px) {
    .container {
        margin-top: 85px;
    }
}
.examsCategory {
    margin-bottom: 20px;
}

.card {
    margin: 20px 0px;
    margin-inline-end: 40px;
}

.cardHeader {
    display: grid;
    grid-template-columns: 165px 55px;
    grid-template-rows: auto 1fr;
    gap: 0 10px;
    padding: 0px !important;
}

.cardOption {
    grid-column: 2/3;
    grid-row: 1/2;
    width: 100%;
    height: 26px;
    /*box-shadow: 2.4px 4.8px 12px rgba(125, 204, 183, 0.25);*/
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: white;
    background: #6FC4AD;
    padding: 5px 8px;
    margin-top: 4px;
}

.cardHeader h1 {
    font-weight: 700;
    font-size: 28px;
    max-lines: 1;
    overflow: hidden;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.expired {
    background-color: #db6464 !important;
}
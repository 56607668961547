:root {
    --iscoreSec: rgba(63, 147, 211, 1);
    --iscorePrim: #1a5785;
    --iscoreGrey: rgba(185, 185, 195, 1);
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 11px !important;

}

aside {
    z-index: 10;
}

.logout {
    position: absolute;
    bottom: 0px;
}

/* .settingsLink {
  position: absolute;
  bottom: 15px;
  left: 0px;
} */

/* .ant-layout-sider-collapsed { */
/* background-color: red !important; */
/* min-width: 44px !important; */
/* } */

.ant-layout-sider {
    position: sticky !important;
    min-height: 100vh;
    height: fit-content;
    background: #001731;
    border-radius: 0px 30px 30px 0px;
    top: 0px;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
    margin-inline-end: 10px;
    max-height: 100vh;
    color: #ffffff;
}

.ant-menu {
    background: #001731;
    color: #ffffff;
    border-radius: 0px 30px 0px 0px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

.ant-menu-title-content a {
    color: #ffffff !important;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    border-radius: 15px !important;
}

.ant-layout-sider-collapsed {
    flex: 0 0 47px !important;
    max-width: 80px !important;
    min-width: 47px !important;
    width: 47px !important;
}

.ant-layout-sider-trigger {
    clip-path: inset(-8px -8px -8px 0px);
    box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 15%) !important;
    color: #fff !important;
    background: #6fc4ad !important;
    width: 30px !important;
    height: 30px !important;
    position: absolute !important;
    right: -19px;
    top: 10% !important;
    border-radius: 50%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.image-div,
.name-div {
    display: flex;
    /* justify-content: center; */
    padding: 25px 14px;
    margin-bottom: 25px;
    align-items: center;
}

.image-div {
    margin-left: 2px;
}

.ant-layout-sider-collapsed {
    min-width: 60px !important;
}

.ant-layout-sider-collapsed .image-div,
.ant-layout-sider-collapsed .name-div {
    padding: 25px 0px;
}

.ant-menu-item svg,
.ant-menu-submenu svg {
    color: #fff;
    position: relative;
    left: 4px;
    bottom: 2px
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
    margin: -2.5px
}

.ant-menu-item-selected svg {
    color: #ffffff !important;
}

.ant-menu-title-content {
    color: black !important;
}

.general {
    font-size: 12px;
    line-height: 16px;
    padding: 20px 20px 8px;
    text-transform: uppercase;
    margin-top: 20px;
    color: #6d7175;
}

.activeItem {
    background: rgba(225, 225, 225, 0.1) !important;
    border-radius: 10px;
}

.activeItem svg {
    color: #ffffff !important;
}

.ant-menu-inline .ant-menu-item::after {
    border-right: unset !important;
}

.ant-menu-item-icon a {
    color: #001731 !important;
}

@media (max-width: 599px) {
    .MuiToolbar-root {
        display: block !important;
    }
}

.sidemenu {
    position: relative;
}

.logo-animation {
    animation-name: example;
    animation-duration: 1s;
    animation-direction: normal;
    animation-timing-function: ease-in;
}

@keyframes example {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: unset !important;
}

.user {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.name-block {
    max-width: 44px;
    width: 100%;
    max-height: 44px;
    background: #6fc4ad;
    border-radius: 8px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name-block p {
    font-weight: 600;
    height: fit-content;
    font-size: 16px;
    color: #ffffff !important;
    margin: 22px auto;
}

.wallet {
    position: absolute !important;
    bottom: 65px;
    left: 10px !important;
}

.logout {
    position: absolute !important;
    left: 10px !important;
    bottom: 20px;
}


.container {
    margin-inline: 50px;
    font-size: 15px;
}

.container header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
}


.questionHeader {
    font-size: 24px !important;
}

.sheetsList {
    display: flex;
    flex-direction: column;
    gap: 12px;
}


.sheetItem {
    padding: 16px 24px;
    border: 1.5px solid lightgrey;
    border-radius: 6px;

}

.question {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 24px;
}

.answers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 24px;
}

.answer {
    border: 1px solid lightgrey;
    border-radius: 6px;
    padding: 12px;
    font-size: 14px;
}


.studentAnswer {
    border: 1.5px solid #0AAD8A;
}

.imageContainer img {
    max-width: 100%;
    border-radius: 4px;
    margin-bottom: 8px;
}

.imageContainer {
    margin-top: 10px;
}

.mark {
    display: flex;
    flex-direction: row;
    font-size: 26px;
    align-items: flex-end;
}

.mark input {
    width: 80px;
    padding: 0px;
    font-size: 48px;
    text-align: end;
    border-radius: 4px;
}

.submitGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    position: sticky;
    margin: 16px 0px;
    padding: 8px 8px 8px 16px;
    border-radius: 8px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
    border: 1px solid lightgrey;
    bottom: 16px;
}

.finalGrade {
    font-size: 20px;
    font-weight: bold;
    flex:1;
}

.finalGrade div {
    display: inline-block;
    margin-inline-end: 16px;
}

.finalGrade span {
    font-size: 26px;
}

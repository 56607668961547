.primaryButton,
.secondaryButton {
    height: 48px;
    text-align: center;
    padding-inline: 32px;

    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    outline: none;
    border: none;
    transition: background 100ms ease-in-out, box-shadow 100ms ease-in-out;
}

.primaryButton {
    background: linear-gradient(99.62deg, #0AAD8A -98.01%, #0AAD69 76.21%);
    color: white;
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.25);
}

.primaryButton:hover {
    background: linear-gradient(98.94deg, #0AAD8A 56.32%, #0AAD69 135.72%);
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.25);
}

.primaryButton:active {
    background: linear-gradient(99.62deg, #077A62 -98.01%, #077A4A 76.21%);
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.25);
}

.primaryButton:disabled {
    background: darkgray;
    cursor: not-allowed;
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.25);
}


.secondaryButton {
    background-color: transparent;
    border: 1px solid #0BAE6A;
    color: #0BAE6A;
}

.secondaryButton:hover {
    background: linear-gradient(0deg, rgba(11, 174, 106, 0.1), rgba(11, 174, 106, 0.1)), #FFFFFF;
}

.secondaryButton:active {
    background: linear-gradient(0deg, rgba(10, 145, 88, 0.24), rgba(10, 145, 88, 0.24)), #FFFFFF;
}

.cardd {
    font-family: "Inter", sans-serif;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 14px;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    min-height: 405px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px;
    width: 290px;
    background-color: #0a2a38 !important;
}

.cardd .cardInfoHolder {
    padding: 0 10px;
    width: 90%;
}

.cardd .cardTitle {
    font-weight: 700;
    font-size: 28px;
    overflow: hidden;
    color: #ffffff;
    margin-bottom: 5px;
}

.cardd .cardDesc {
    font-weight: 300;
    font-size: 13px;

    color: #ffffff;
    display: -webkit-box;
    /* max-width: 200px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.cardPlayBtn {
    background: #6fc4ad;
    color: #ffffff;
    width: 36px !important;
    height: 36px !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

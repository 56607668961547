:root {
  --first-color-accent: #ff8f44;
  --second-color-accent: #ff4e77;
  --third-color-accent: #33be94;
  --color-primary: #1b3b56;
  --common-color-font: #8e9eab;

  --font-size-p: 8px;
  --font-size-header: 17px;
  --font-size-code: 7px;

  --dimen-card-width: 252px;
}



.card {
  width: 252px;
  height: 144px;
  border: 1px solid;
  position: relative;
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 6px;
  margin-top: 2px;

}

.cardTop {
  height: calc(100% - 22px);
  margin: 6px 9px;
  display: flex;
  flex-direction: column;
}

.cardHeader {
  display: flex;
  flex-direction: column;
  /* display: inline-block; */

  /* background-color: #ff4e77; */
}

.cardFooter {
  background-repeat: no-repeat;
  background-position: center;
  height: 28px;
  /* background-color: antiquewhite; */
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin-bottom: 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.snContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  gap: 1px;
  margin-inline-end: 4px;
}

.snContainer h6 {
  font-size: var(--font-size-p);
  color: #eef1ef;
  font-weight: bold;
}

.snNumber {
  font-size: var(--font-size-code);
  font-weight: bold;
  color: var(--color-primary);
  background-color: #eef1ef;
  border-radius: 100px;
  padding: 0px 5px;
}

.card h1,
.card h4,
.card h6 {
  margin-bottom: 0;
  padding: 0;
  font-family: 'Poppins';
}


.cardHeader h1 {
  display: inline-block;
  float: left;
  font-weight: bold;
  font-size: var(--font-size-header);
}

.card h4 {
  font-size: var(--font-size-p);
  margin-bottom: 4px;
  margin-inline-start: 5.3px;
}

.scratchCodeContainer {
  border: 2px solid;
  border-radius: 100px;
  width: 112.8px;
  height: 13.4px;
  text-align: center;
  font-size: var(--font-size-code);
  padding: 0px;
  line-height: 9px;
  border-color: var(--color-primary);
}

.cardHeader hr {
  padding: 0;
  margin: 0;
  height: 1px;
  width: 133px;
  border-radius: 10px;
  margin-bottom: 15px;
  background-color: var(--color-primary);
  opacity: 1;
  color: var(--color-primary);
}

.content {
  flex: 1;
  width: 112.8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.card p,
.card a {
  font-family: 'Poppins';
  font-size: var(--font-size-p);
  line-height: 10px;
  margin: 0px;
  color: var(--color-primary);
}

.card p {
  margin-top: 7px;
}

.card a {
  margin-top: 3px;
  font-weight: bold;
  text-decoration: underline;
}

.card p span {
  font-weight: bold;
}

/* variants */

.first {
  background-image: url("./res/background/background-first.svg");
}

.first .cardFooter {
  background-image: url("./res/logo/logo-first.svg");
}

.first h1,
.first h4,
.first p span {
  color: var(--first-color-accent);
}

.second .cardFooter {
  background-image: url("./res/logo/logo-second.svg");
}

.second {
  background-image: url("./res/background/background-second.svg");
}

.second h1,
.second h4,
.second p span {
  color: var(--second-color-accent);
}

.third {
  background-image: url("./res/background/background-third.svg");
}

.third .cardFooter {
  background-image: url("./res/logo/logo-third.svg");
}

.third h1,
.third h4,
.third p span {
  color: var(--third-color-accent);
}

@media print {

  .card {
    display: inline-block;
    break-inside: avoid;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
  }

  .card {
    border: none;
  }
}